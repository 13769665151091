import React, { Fragment } from "react"


const Footer = () => {

    return (
        <Fragment>
            <div className="footer-home"></div>
        </Fragment>
    )
}

export default Footer